import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../components/table";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetDriversEarning } from "../../redux/slices/driverSlice";
import { BUCKET_PUBLIC_URL } from "../../constants";
import { useClearSattlementRequestsQuery } from "../../redux/services/paymentAPI";
import TableV2 from "../../components/tableV2";
import { useDriverEarningsQuery } from "../../redux/services/driversAPI";

function DriversEarningsTable() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);


  const [query, setQuery] = useState("");

  const searchValueHandler = (value) => {
    setQuery(value);
  };

  const columns = [
    {
      displayName: "Driver",
      displayField: (e) => (
        <div className="d-flex align-items-center">
          <img
            width={40}
            height={40}
            src={`${BUCKET_PUBLIC_URL}/${e.driver.profileImageUrl}`}
            className="rounded-circle object-fit-cover"
          />
          <p className="ml-2 mb-0">
            {e.driver.firstName} {e.driver.lastName}
          </p>
        </div>
      ),
      searchable: true,
    },
    {
      displayName: "Total Earnings",
      displayField: (e) => (
        <>
          {e?.currency} {e.totalEarnings}
        </>
      ),
      searchable: true,
    },
    {
      displayName: "Total Cash Collected",
      displayField: (e) => (
        <>
          {e?.currency} {e.totalCashAmount}
        </>
      ),
      searchable: true,
    },

    {
      displayName: "Received Wallet Amount",
      displayField: (e) => (
        <>
          {e?.currency} {e.totalWalletAmount}
        </>
      ),
    },
  ];

  const driversEarnings = useSelector((state) => state.drivers.driversEarnings);
  const auth = useSelector((state) => state.auth);

  // const { isLoading, list } = driversEarnings;

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(asyncGetDriversEarning());
  // }, [auth.country]);


  const {
    data: list,
    isLoading,
    error,
  } = useDriverEarningsQuery({
    page,
    limit,
    country: auth.country,
    query,
  });


  console.log(list, "listlist")

  return (
    <div>
      {/* <Table dataSource={list} isLoading={isLoading} columns={columns} /> */}
      <TableV2
        dataSource={list?.results || []}
        isLoading={isLoading}
        columns={columns}
        currentPage={page}
        setCurrentPage={setPage}
        itemsPerPage={limit}
        setItemsPerPage={setLimit}
        totalEntries={list?.totalResults}
        totalPages={list?.totalPages}
        searchValueHandler={searchValueHandler}
      />
    </div>
  );
}

export default DriversEarningsTable;

import React, { useEffect, useRef, useState } from "react";
import { countryAllData, logout } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import DefaultProfileImg from "../../assets/images/default-profile-img.png";
import { asyncGetCountries } from "../../redux/slices/countrySlice";
import { BUCKET_PUBLIC_URL } from "../../constants";
// import { LoadingAnimation } from "../../assets/loader/loading.gif";
import {
  notificationAPI,
  useGetNotificationAPIQuery,
  // useUpdateNotificationAPIMutation,
  useUpdateNotificationMutation,
} from "../../redux/services/notificationAPI";
import { MdSettings } from "react-icons/md";

// import { addMessage } from "../../redux/slices/noticationSlice";
import socket from "../../services/socket";
// import moment from "moment";
import { calculateMessageTime } from "../../utils/time";
import DotsLoader from "../../components/loading/DotsLoader";
// import HeaderOfAllCountry from "../../components/allCounrty";

function Header() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const availableCountries = useSelector((state) => state.countries);

  const handleTheme = (e) => {
    document.body.classList.toggle("dark");
  };
  const toggleSidebar = (e) => {
    document.body.classList.toggle("sidebar-main");
  };

  const logoutHandler = () => {
    dispatch(logout());
  };

  const handleCountryChange = (e) => {
    dispatch(countryAllData(e.target.value));
  };

  useEffect(() => {
    dispatch(asyncGetCountries());
  }, []);

  const [page, setPage] = useState(1);
  const {
    data: notifications,
    // isLoading,
    // error,
    isFetching,
  } = useGetNotificationAPIQuery({ page });

  const [updateNotification] = useUpdateNotificationMutation();

  const notificationIsRead = notifications?.results?.filter(
    (items) => items.isRead !== true
  );

  const readNotificationHandler = async (id, isRead) => {
    await updateNotification(id)
      .unwrap()
      .then((res) => {
        dispatch(
          notificationAPI.util.updateQueryData(
            "getNotificationAPI",
            { page: 1, pageSize: 10 },
            (draft) => {
              draft.results = draft.results.map((x, i) =>
                x._id == id ? { ...x, isRead: true } : x
              );
            }
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const notificationContainerRef = useRef(null);

  function handleScroll() {
    const container = notificationContainerRef.current;

    if (!container) return;

    const scrolled =
      parseInt(container.scrollHeight - container.scrollTop) ===
      container.clientHeight;

    if (scrolled) {
      if (!isFetching) {
        if (!notifications || notifications.totalPages > page) {
          setPage(page + 1);
        }
      }
    }
  }

  useEffect(() => {
    const handleNewNotification = (data) => {
      // Assuming 'data' is the new notification object you want to add to the list

      dispatch(
        notificationAPI.util.updateQueryData(
          "getNotificationAPI",
          { page: 1, pageSize: 10 },
          (draft) => {
            draft.results.unshift(data);
          }
        )
      );
      const audio = new Audio("/notification/notification.wav");
      audio.play();
    };

    socket.on("new-notification", handleNewNotification);

    return () => {
      socket.off("new-notification");
    };
  }, []);

  // const [isFullScreen, setIsFullScreen] = useState(false);

  // const toggleFullScreen = () => {
  //   const element = document.documentElement;
  //   if (!isFullScreen) {
  //     if (element.requestFullscreen) {
  //       element.requestFullscreen();
  //     } else if (element.mozRequestFullScreen) {
  //       element.mozRequestFullScreen();
  //     } else if (element.webkitRequestFullscreen) {
  //       element.webkitRequestFullscreen();
  //     } else if (element.msRequestFullscreen) {
  //       element.msRequestFullscreen();
  //     }
  //     setIsFullScreen(true);
  //   } else {
  //     if (document.exitFullscreen) {
  //       document.exitFullscreen();
  //     } else if (document.mozCancelFullScreen) {
  //       document.mozCancelFullScreen();
  //     } else if (document.webkitExitFullscreen) {
  //       document.webkitExitFullscreen();
  //     } else if (document.msExitFullscreen) {
  //       document.msExitFullscreen();
  //     }
  //     setIsFullScreen(false);
  //   }
  // };

  return (
    <div className="mm-top-navbar">
      <div className="mm-navbar-custom">
        <nav className="navbar navbar-expand-lg navbar-light p-0">
          <div className="mm-navbar-logo d-flex align-items-center justify-content-between">
            <i className="fas fa-bars wrapper-menu" onClick={toggleSidebar} />
            {/* Logo */}
          </div>
          <div className="mm-search-bar device-search ">
            {/* <p
              className="m-0 p-0"
              style={{ fontSize: "26px", fontFamily: "inherit" }}
            >
              Dashboard             
            </p> */}
          </div>
          <div className="header-Right-side gap-5">
            {/* <HeaderOfAllCountry /> */}
            {/* <div className="Header-VerticalLine"></div> */}

            {auth?.userInfo?.role === "super_admin" && (
              <div className="change-mode box">
                <div className="custom-control custom-switch custom-switch-icon custom-control-inline">
                  <div className="custom-switch-inner">
                    <p className="mb-0"> </p>
                    <select
                      className="form-control"
                      required
                      name="country"
                      onChange={handleCountryChange}
                      value={auth.country}
                    >
                      <option value="" className="">
                        All Countries
                      </option>

                      {availableCountries?.list?.map((item) => (
                        <option value={item._id || item._id}>
                          {" "}
                          {item.name}{" "}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}
            <div className="change-mode box">
              <div className="custom-control custom-switch custom-switch-icon custom-control-inline">
                <div className="custom-switch-inner">
                  <p className="mb-0"> </p>
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="dark-mode"
                    data-active="true"
                    onChange={handleTheme}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="dark-mode"
                    data-mode="toggle"
                  >
                    <span className="switch-icon-left">
                      <svg
                        className="svg-icon"
                        id="h-moon"
                        height={20}
                        width={20}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                          style={{
                            strokeDasharray: "57, 77",
                            strokeDashoffset: 0,
                          }}
                        />
                      </svg>
                    </span>
                    <span className="switch-icon-right">
                      <svg
                        className="svg-icon"
                        id="h-sun"
                        height={20}
                        width={20}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                          style={{
                            strokeDasharray: "34, 54",
                            strokeDashoffset: 0,
                          }}
                        />
                      </svg>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="Header-VerticalLine"></div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-label="Toggle navigation"
            >
              <i className="ri-menu-3-line" />
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto navbar-list align-items-center">
                <li className="nav-item nav-icon dropdown">
                  <a
                    href="#"
                    className="search-toggle dropdown-toggle notification_list"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {/* <svg
                      className="svg-icon text-primary"
                      id="mm-bell-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}      
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path
                        d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"
                        style={{
                          strokeDasharray: "57, 77",
                          strokeDashoffset: 0,
                        }}
                      />
                      <path
                        d="M13.73 21a2 2 0 0 1-3.46 0"
                        style={{
                          strokeDasharray: "5, 25",
                          strokeDashoffset: 0,
                        }}
                      />
                    </svg> */}
                    <svg
                      width="16"
                      height="18"
                      viewBox="0 0 21 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.4 26C8.96402 26 7.8 24.836 7.8 23.4H13C13 24.836 11.836 26 10.4 26ZM20.8 22.1H0V19.5L2.6 18.2V11.05C2.6 6.5494 4.4473 3.6309 7.8 2.834V0H11.7C10.8523 1.12312 10.3957 2.49291 10.4 3.9C10.4 4.22679 10.4243 4.55313 10.4728 4.8763H10.4C8.81387 4.77356 7.28391 5.48294 6.3375 6.76C5.52831 8.03993 5.13128 9.53729 5.2 11.05V19.5H15.6V11.05C15.6 10.7757 15.5909 10.5118 15.5727 10.27C16.4326 10.4476 17.3194 10.4507 18.1805 10.2791C18.1948 10.5534 18.2 10.8147 18.2 11.0591V18.2L20.8 19.5V22.1ZM16.9 7.8C16.1145 7.80095 15.3471 7.56391 14.6991 7.1201C13.1674 6.07305 12.5854 4.09189 13.3074 2.38277C14.0295 0.67366 15.8556 -0.290186 17.674 0.078052C19.4925 0.446277 20.7997 2.04464 20.8 3.9C20.8 6.0539 19.054 7.8 16.9 7.8Z"
                        fill="#36B34E"
                      />
                    </svg>

                    <span className="bg-primary " />
                    <span className="badge badge-pill badge-primary badge-up notify_count count-mail d-none" />
                    {notifications && notificationIsRead.length !== 0 ? (
                      <span
                        className=" text-white px-2 py-1 rounded-pill"
                        style={{ fontSize: 12, backgroundColor: "#36B34E" }}
                      >
                        {notifications && notificationIsRead.length}
                      </span>
                    ) : (
                      <span className="bg-primary dots" />
                    )}
                  </a>

                  <div
                    className="mm-sub-dropdown dropdown-menu notification-menu shadow_box py-1 "
                    aria-labelledby="dropdownMenuButton  "
                  >
                    <div className="card shadow-none m-0 border-0 ">
                      <div className={" notification_data shadow"}>
                        {notifications && notifications.results.length === 0 ? (
                          <div
                            className="h-100 w-100 d-flex align-items-center justify-content-center"
                            style={{ height: "100px", overflowY: "scroll" }}
                          >
                            No notifications
                          </div>
                        ) : (
                          <>
                            <div className="bg-white">
                              <p
                                className="m-0 pl-2 py-2"
                                style={{
                                  fontSize: 14,
                                  fontWeight: "bold",
                                  borderBottom: "1px solid #F1F1F1",
                                }}
                              >
                                Notification
                              </p>
                            </div>
                            <div
                              className="notification-list"
                              style={{
                                maxHeight: "400px",
                                height: "100%",
                                overflowY: "scroll",
                              }}
                              ref={notificationContainerRef}
                              onScroll={handleScroll}
                            >
                              {notifications?.results?.map(
                                (notification, index) => (
                                  <div
                                    onClick={() =>
                                      readNotificationHandler(
                                        notification._id,
                                        notification.isRead
                                      )
                                    }
                                    className="notification-message text-black"
                                    key={index}
                                    style={{
                                      padding: "0px 10px",
                                      borderBottom:
                                        index !== notifications.length - 1
                                          ? "1px solid #F1F1F1"
                                          : null,
                                    }}
                                  >
                                    <Link
                                      href={notification?.redirectTo}
                                      style={{ color: "black" }}
                                    >
                                      <div
                                        className=" d-flex "
                                        style={{
                                          gap: "10px",
                                          padding: "5px 10px",
                                        }}
                                      >
                                        <div className="avatar avatar-sm">
                                          {notification?.sender
                                            ?.profileImageUrl ? (
                                            <img
                                              src={`${BUCKET_PUBLIC_URL}/${notification?.sender?.profileImageUrl}`}
                                              width={30}
                                              height={30}
                                              alt="Logo"
                                              className="rounded-circle"
                                            />
                                          ) : (
                                            <MdSettings
                                              className="text-primary"
                                              style={{ width: 30, height: 30 }}
                                            />
                                          )}
                                        </div>
                                        <div className="flex flex-column gap-3 justify-content-between w-100">
                                          {notification?.sender?.firstName ? (
                                            <span
                                              className="noti-title m-0 p-0"
                                              style={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {notification?.sender?.firstName}{" "}
                                              {notification?.sender?.lastName}{" "}
                                            </span>
                                          ) : null}

                                          <span
                                            className="m-0"
                                            style={{ fontSize: 14 }}
                                          >
                                            {notification?.title}
                                          </span>
                                          <div className="d-flex justify-content-between align-items-end py-2">
                                            <p
                                              className="noti-time notification-time m-0 pt-1 "
                                              style={{
                                                color: "gray",
                                                fontSize: 13,
                                              }}
                                            >
                                              {calculateMessageTime(
                                                notification.createdAt
                                              )}
                                            </p>

                                            <p className="badge badge-primary m-0 ">
                                              {notification.isRead ? "" : "New"}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                )
                              )}
                              {isFetching && (
                                <div className="d-flex justify-content-center bg-white py-3 px-4 ">
                                  <DotsLoader dark />
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </li>

                {/* <li className="nav-item nav-icon dropdown full-screen">
                  <a
                    href="#"
                    className="nav-item nav-icon dropdown"
                    id="btnFullscreen"
                    onClick={toggleFullScreen}
                  >
                    {isFullScreen ? (
                      <i className="min ">
                        <svg
                          className="svg-icon text-primary"
                          id="d-3-min"
                          width={20}
                          height={20}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path
                            d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3"
                            style={{
                              strokeDasharray: "37, 57",
                              strokeDashoffset: 0,
                            }}
                          />
                        </svg>
                      </i>
                    ) : (
                      <i className="max">
                        <svg
                          className="svg-icon  text-primary"
                          id="d-3-max"
                          width={20}
                          height={20}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path
                            d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"
                            style={{
                              strokeDasharray: "37, 57",
                              strokeDashoffset: 0,
                            }}
                          />
                        </svg>
                      </i>
                    )}
                  </a>
                </li> */}
                {/* <li className="nav-item nav-icon dropdown">
                  <a
                    href="#"
                    className="nav-item nav-icon dropdown-toggle pr-0 search-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="mr-2">
                      {auth.userInfo.firstName} {auth.userInfo.lastName}
                    </span>
                    <img
                      src={`${BUCKET_PUBLIC_URL}/${auth.userInfo.profileImageUrl}`}
                      // src="https://meetmighty.com/mobile/mighty-taxi/images/user/1.jpg"
                      className="img-fluid avatar-rounded"
                      alt="user"
                    />
                  </a>
                  <ul
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li className="dropdown-item d-flex ">
                      <svg
                        className="svg-icon mr-0 text-primary"
                        id="h-01-p"
                        width={20}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                          style={{
                            strokeDasharray: "90, 110",
                            strokeDashoffset: 0,
                          }}
                        />
                      </svg>
                      <Link to="/profile">My Profile</Link>
                    </li>
                    <li className="dropdown-item d-flex ">
                      <svg
                        className="svg-icon mr-0 text-primary"
                        id="h-03-p"
                        width={20}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                          style={{
                            strokeDasharray: "63, 83",
                            strokeDashoffset: 0,
                          }}
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          style={{
                            strokeDasharray: "19, 39",
                            strokeDashoffset: 0,
                          }}
                        />
                      </svg>
                      <Link to="/setting">Setting</Link>
                    </li>
                    <li className="dropdown-item d-flex border-top">
                      <svg
                        className="svg-icon mr-0 text-primary"
                        id="h-05-p"
                        width={20}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                          style={{
                            strokeDasharray: "65, 85",
                            strokeDashoffset: 0,
                          }}
                        />
                      </svg>
                      <Link to="" className="pl-1" onClick={logoutHandler}>
                        Log out
                      </Link>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </div>

            <div className="box">
              <a
                href="#"
                className=" dropdown-toggle d-flex align-items-center ml-2"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  src={`${BUCKET_PUBLIC_URL}/${auth.userInfo.profileImageUrl}`}
                  // src="https://meetmighty.com/mobile/mighty-taxi/images/user/1.jpg"
                  className="avatar-rounded mr-2"
                  alt="user"
                  height={40}
                />
                <span className="mr-2 text-white">
                  {auth.userInfo.firstName} {auth.userInfo.lastName}
                </span>
              </a>
              <ul
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenuButton"
              >
                <li className="dropdown-item d-flex ">
                  <svg
                    className="svg-icon mr-0 text-primary"
                    id="h-01-p"
                    width={20}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      style={{
                        strokeDasharray: "90, 110",
                        strokeDashoffset: 0,
                      }}
                    />
                  </svg>
                  <Link to="/profile">My Profile</Link>
                </li>
                <li className="dropdown-item d-flex ">
                  <svg
                    className="svg-icon mr-0 text-primary"
                    id="h-03-p"
                    width={20}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                      style={{
                        strokeDasharray: "63, 83",
                        strokeDashoffset: 0,
                      }}
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      style={{
                        strokeDasharray: "19, 39",
                        strokeDashoffset: 0,
                      }}
                    />
                  </svg>
                  <Link to="/setting">Setting</Link>
                </li>
                <li className="dropdown-item d-flex border-top">
                  <svg
                    className="svg-icon mr-0 text-primary"
                    id="h-05-p"
                    width={20}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                      style={{
                        strokeDasharray: "65, 85",
                        strokeDashoffset: 0,
                      }}
                    />
                  </svg>
                  <Link to="" className="pl-1" onClick={logoutHandler}>
                    Log out
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
